'use strict';

import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import ReactHTMLParser from 'react-html-parser';

import { Lframe } from '@latitude/lframe';
import { StickyNavigation } from '@latitude/sticky-navigation';
import {
  ALIGN,
  BREAKPOINT,
  BUTTON_STYLE,
  FONT_SIZE,
  FONT_FAMILY,
  MARGIN,
  COLOR
} from '@latitude/core/utils/constants';
import HowToApply from '@latitude/how-to-apply';
import { Link } from '@latitude/link';
import { FramedContainer } from '@latitude/framed-container';
import { Accordion } from '@latitude/accordion';
import { Heading3 } from '@latitude/heading';
import Section from '@latitude/section';
import Text from '@latitude/text/Text';

import HeroBranded from '@/components/HeroBranded/HeroBranded';
import Layout from '@/components/layout';
import { SITE_URL } from '../../utils/constants';

import ContactingLatitude from './_contacting-latitude';
import imageHero from './images/lets-talk-security-background.png';
import imgHowWeProtectYou from './images/data-encryption.png';
import imgHowToProtectYourself from './images/sunglasses.png';
import PageData from './data/security-landing.json';
import './index.css';

const SecurityLandingPage = ({ location, data }) => {
  const securityItems = data.allMarkdownRemark.edges.map(edge => {
    return {
      ...edge.node.frontmatter,
      content: (
        <div className="container security-latest-scams-item">
          {ReactHTMLParser(edge.node.html)}
        </div>
      )
    };
  });

  return (
    <Layout location={location} noMetaKeywords title={PageData.title}>
      <main
        className="navigation-spacer"
        css={`
          background-color: ${COLOR.GREY6};
        `}
      >
        <Helmet>
          <link rel="canonical" href={`${SITE_URL}${location.pathname}`} />
          <title>{PageData.meta.title}</title>
          <meta name="description" content={PageData.meta.description} />
        </Helmet>

        <div
          css={`
            background-image: linear-gradient(#faebf2, transparent);
            padding-bottom: 30pt;
          `}
        >
          <Lframe bgImage={imageHero} />
          <HeroBranded
            title={PageData.hero.title}
            text={PageData.hero.text}
            breadcrumbs={PageData.hero.breadcrumbs}
            css={`
              @media (min-width: ${BREAKPOINT.MD}) {
                .HeroText {
                  padding-bottom: 20pt;
                }
              }
            `}
          />
        </div>

        <StickyNavigation
          items={PageData.stickyNavigation}
          trackId="how-we-protect-you-stickynav"
        />

        <Section className="pt-lg-5 pb-lg-5">
          <div
            id={PageData.stickyNavigation[0].anchor}
            css={`
              margin: 0 10%;
              padding: 15pt;
            `}
          >
            <SectionHeading>
              {PageData.stickyNavigation[0].label}
            </SectionHeading>
            <Text>
              Latitude Financial is responding to a cyber-attack that has
              resulted in the theft of personal information. The theft impacts
              customers across Australia and New Zealand. For the latest
              information, please visit our{' '}
              <a href="/cyber-response/">dedicated response page</a>.
            </Text>
          </div>
        </Section>

        <ContactingLatitude
          anchor={PageData.stickyNavigation[1].anchor}
          heading={PageData.stickyNavigation[1].label}
        />

        <StayingSafeAndSecureSection
          id={PageData.stickyNavigation[2].anchor}
          title={PageData.stickyNavigation[2].label}
        />

        <Section
          className="security-latest-scams"
          css={`
            background-color: #e8f4ff;
          `}
        >
          <FramedContainer
            theme={{
              frameColor: COLOR.BLUE_SKY,
              contentBorderColor: COLOR.TRANSPARENT
            }}
          >
            <div
              id={PageData.stickyNavigation[3].anchor}
              css={`
                margin: 0 10%;
                padding: 15pt;
              `}
            >
              <SectionHeading>
                {PageData.stickyNavigation[3].label}
              </SectionHeading>
              <Text>
                If you believe you have received Gem branded scam, report to:{' '}
                <a href="mailto:scams@latitudefinancial.com">
                  scams@latitudefinancial.com
                </a>
                . Please be aware this is a reporting service. You may{' '}
                <b>not</b> receive a personal response from{' '}
                <a href="mailto:scams@latitudefinancial.com">
                  scams@latitudefinancial.com
                </a>
                .
              </Text>
              <Text
                css={`
                  margin-top: 5pt;
                `}
              >
                If you have responded to or actioned a suspicious email or
                message, please contact Latitude immediately.
              </Text>

              <Accordion
                css={`
                  margin-top: 5pt;
                `}
                titleBgColor="grey-light"
                items={securityItems}
              />
            </div>
          </FramedContainer>
        </Section>

        <ResponsibleDisclosureSection
          id={PageData.stickyNavigation[4].anchor}
          title={PageData.stickyNavigation[4].label}
        />
      </main>
    </Layout>
  );
};

const StayingSafeAndSecureSection = ({ id, title }) => {
  const boxHowWeProtectYou = (
    <div
      css={`
        text-align: center;
      `}
    >
      <img
        src={imgHowWeProtectYou}
        alt="How We Protect You"
        css={`
          width: 80%;
          margin: 0 auto;
        `}
      />
      <BoxHeading>How we Protect You</BoxHeading>
      <Text align={ALIGN.CENTER}>
        See how we manage and protect your personal information.
      </Text>
      <Link
        href={'/security/how-we-protect-you/'}
        button={BUTTON_STYLE.TERTIARY}
        css={`
          margin: 15pt 25% 0 25%;
        `}
      >
        Find out more
      </Link>
    </div>
  );

  const boxHowToProtectYourself = (
    <div
      css={`
        text-align: center;
      `}
    >
      <img
        src={imgHowToProtectYourself}
        alt="How to Protect Yourself"
        css={`
          width: 80%;
          margin: 0 auto;
        `}
      />
      <BoxHeading>How To Protect Yourself</BoxHeading>
      <Text align={ALIGN.CENTER}>
        Learn how to stay safe and protect yourself online.
      </Text>
      <Link
        href={'/security/how-to-protect-yourself/'}
        button={BUTTON_STYLE.TERTIARY}
        css={`
          margin: 15pt 25% 0 25%;
        `}
      >
        Find out more
      </Link>
    </div>
  );

  return (
    <>
      <SectionHeading id={id}>{title}</SectionHeading>
      <HowToApply box1={boxHowWeProtectYou} box2={boxHowToProtectYourself} />
    </>
  );
};

const ResponsibleDisclosureSection = ({ id, title }) => {
  const rdLogo = require('./images/icon-thumbs-up.svg').default;

  return (
    <Section
      id={id}
      className="security-responsible-disclosure"
      css={`
        background-color: ${COLOR.GREY10};
      `}
    >
      <div className="row">
        <div className="col-12">
          <section className="important-info">
            <header className="important-info__header">
              <img
                src={rdLogo}
                css={`
                  height: 3em;
                  width: auto;
                `}
              />
              <ImportantInfoTitle>{title}</ImportantInfoTitle>
            </header>
            <div className="row">
              <div className="col-10 offset-1 col-lg-5 offset-lg-1">
                <Text
                  className="important-info__text"
                  fontSize={FONT_SIZE.SMALL}
                  css={`
                    margin-bottom: ${MARGIN.M16};
                  `}
                >
                  <strong>
                    Effective 10 November 2021 until further notice.
                  </strong>
                </Text>
                <Text
                  className="important-info__text"
                  fontSize={FONT_SIZE.SMALL}
                  css={`
                    margin-bottom: ${MARGIN.M16};
                  `}
                >
                  At Latitude, we understand how important it is to protect our
                  customers and we value the input of the security community.
                  The responsible disclosure of security vulnerabilities helps
                  us ensure the security and privacy of Latitude and our
                  customers.
                </Text>
                <Text
                  className="important-info__text"
                  fontSize={FONT_SIZE.SMALL}
                  css={`
                    margin-bottom: ${MARGIN.M16};
                  `}
                >
                  If you believe you have found a security vulnerability with
                  any of our services, we would like you to let us know right
                  away via our Responsible Disclosure Program.
                </Text>
                <Text
                  className="important-info__text"
                  fontSize={FONT_SIZE.SMALL}
                  css={`
                    margin-bottom: ${MARGIN.M16};
                  `}
                >
                  Latitude does not condone any malicious or illegal behaviour
                  in the identification and reporting of security
                  vulnerabilities.
                </Text>
              </div>

              <div className="col-10 offset-1 col-lg-5 offset-lg-0">
                <Text
                  className="important-info__text"
                  fontSize={FONT_SIZE.SMALL}
                  css={`
                    margin-bottom: ${MARGIN.M16};
                  `}
                >
                  Our Responsible Disclosure Program is managed by Bugcrowd.
                </Text>
                <Text
                  className="important-info__text"
                  fontSize={FONT_SIZE.SMALL}
                  css={`
                    margin-bottom: ${MARGIN.M16};
                  `}
                >
                  If you have discovered a vulnerability that you wish to report
                  to us, this should be done through Bugcrowd. We encourage
                  researchers to register with Bugcrowd and report any findings
                  through the Bugcrowd platform. If this is not available to
                  you, you may make a manual submission using the vulnerability
                  submission form.
                </Text>
                <Text
                  className="important-info__text"
                  fontSize={FONT_SIZE.SMALL}
                  css={`
                    margin-bottom: ${MARGIN.M16};
                  `}
                >
                  Click here to access the{' '}
                  <a href="/security/responsible-disclosure/" target="_blank">
                    vulnerability submission form
                  </a>
                  .
                </Text>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Section>
  );
};

const SectionHeading = styled.h2`
  margin: 20px 0;
  color: #000;
  text-align: center;
  font-family: ${FONT_FAMILY.TITLE};
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  @media (min-width: ${BREAKPOINT.LG}) {
    font-size: 32px;
    line-height: 40px;
  }
`;

const BoxHeading = styled.h3`
  color: #000;
  font-size: 22px;
  font-weight: 600;
  margin-top: 0;
`;

const ImportantInfoTitle = styled(Heading3)`
  font-size: 20px;
  line-height: 32px;
  margin: 0 0 0 12px;
  color: ${COLOR.BLACK};
  font-weight: 600;

  @media (min-width: ${BREAKPOINT.LG}) {
    font-size: 24px;
    margin: 0 0 0 24px;
  }
`;

export default SecurityLandingPage;

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/src/data/pages/security/*.md" } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            title
          }
        }
      }
    }
  }
`;
